var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[(_vm.loading && _vm.book)?[_c('div',{staticClass:"list-book"},[_c('div',{staticClass:"list-book-cover"},[_c('van-image',{attrs:{"fit":"contain","src":_vm.book.coverPicUrl == ''
              ? 'https://53english.cdn.53online.cn/bookCover.png'
              : _vm.book.coverPicUrl}})],1),_c('div',{staticClass:"list-book-flex"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.book.bookName)}}),(_vm.book.allResUrl)?_c('div',{staticClass:"down",on:{"click":_vm.tapPopup}},[_vm._v(" 下载整书资源 ")]):_vm._e()])]),_c('dl',{staticClass:"list-type"},[_c('dt',[_vm._v(_vm._s(_vm.book.catalogName))]),(_vm.list.length > 0)?_c('dd',[_vm._l((_vm.list),function(item){return [(item.resFileUrl && item.dataType == 1)?_c('router-link',{key:item.id,attrs:{"to":{
              name: 'audiodetail',
              query: { id: item.id },
            }}},[_c('van-image',{attrs:{"fit":"contain","src":item.dataType == 1 && item.hasFollowRead == 1
                  ? 'https://53english.cdn.53online.cn/%E8%B7%9F%E8%AF%BB.png'
                  : 'https://53english.cdn.53online.cn/%E5%90%AC%E5%8A%9B.png'}}),_c('span',[_vm._v(_vm._s(item.name))])],1):(item.resFileUrl && item.dataType == 2)?_c('router-link',{key:item.id,attrs:{"to":{
              name: 'pdfdetail',
              query: { id: item.id },
            }}},[_c('van-image',{attrs:{"fit":"contain","src":"https://res.cdn.quyixian.com/front/pdf-b.png"}}),_c('span',[_vm._v(_vm._s(item.name))])],1):_c('div',{key:item.id,staticClass:"dd",on:{"click":_vm.tapMes}},[(item.dataType == 2)?_c('van-image',{attrs:{"fit":"contain","src":"https://res.cdn.quyixian.com/front/pdf-b.png"}}):_c('van-image',{attrs:{"fit":"contain","src":item.dataType == 1 && item.hasFollowRead == 1
                  ? 'https://53english.cdn.53online.cn/%E8%B7%9F%E8%AF%BB.png'
                  : 'https://53english.cdn.53online.cn/%E5%90%AC%E5%8A%9B.png'}}),_c('span',[_vm._v(_vm._s(item.name))])],1)]})],2):_vm._e()])]:_c('van-loading',{attrs:{"vertical":"","type":"spinner"}},[_vm._v(_vm._s(_vm.loadingText))]),_c('van-popup',{staticStyle:{"width":"90%"},attrs:{"closeable":""},model:{value:(_vm.downShow),callback:function ($$v) {_vm.downShow=$$v},expression:"downShow"}},[(_vm.loading && _vm.book)?_c('div',{staticClass:"downpopup"},[_c('div',{staticClass:"downpopup-title"},[_vm._v(" "+_vm._s(_vm.book.bookName)+" ")]),_c('div',{staticClass:"downpopup-bottom"},[_c('div',{staticClass:"downpopup-btn"},[(_vm.isIos)?_c('span',{staticClass:"down",on:{"click":function($event){return _vm.downFile(_vm.book.allResUrl)}}},[_vm._v("下载")]):_vm._e(),(_vm.isWxBrowser && !_vm.isIos)?_c('span',{staticClass:"down down2"},[_vm._v("点击右上角···选择默认浏览器打开")]):[(_vm.isAnd)?_c('span',{staticClass:"down",on:{"click":function($event){return _vm.downFile(_vm.book.allResUrl)}}},[_vm._v("下载")]):_vm._e()]],2),_c('div',{staticClass:"downpopup-demo"},[(_vm.isIos)?_c('span',{staticClass:"down1",on:{"click":function($event){_vm.isIosShow = true}}},[_vm._v("苹果手机下载后如何查看资源")]):_vm._e()])]),_c('div',{staticClass:"downpopup-mail"},[_c('van-field',{attrs:{"placeholder":"输入邮箱"},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}}),_c('van-button',{staticClass:"down",on:{"click":_vm.tapMail}},[_vm._v("下载至邮箱")])],1)]):_vm._e()]),_c('van-image-preview',{attrs:{"images":_vm.iosList},model:{value:(_vm.isIosShow),callback:function ($$v) {_vm.isIosShow=$$v},expression:"isIosShow"}}),_c('van-image-preview',{attrs:{"images":_vm.andList},model:{value:(_vm.isAndShow),callback:function ($$v) {_vm.isAndShow=$$v},expression:"isAndShow"}}),_c('div',{staticClass:"offset",on:{"click":function($event){return _vm.$router.back()}}},[_c('van-icon',{attrs:{"name":"arrow-left"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }