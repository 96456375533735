<template>
  <div class="dd-box">
    <div class="dd" v-for="i in list" :key="i.id">
      <template v-if="i.childrenList.length > 0">
        <div class="dd-title">
          <em>{{ i.name }}</em>
        </div>
        <item-lis :list="i.childrenList"></item-lis>
      </template>
      <template v-else>
        <router-link class="unit" :to="{ name: 'list', query: { fid: i.id } }"
          ><em>{{ i.name }}</em
          ><i-circle-double-right theme="outline" size="20" fill="#999"
        /></router-link>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "itemLis",
  props: ["list"],
};
</script>
<style lang="scss" scoped></style>
