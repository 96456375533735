<template>
  <div class="pdf">
    <div class="pdf-loading">PDF文件加载中，稍等片刻！</div>
    <div id="pdf"></div>
    <div class="pdf-footer">
      <div class="pdf-footer-count">
        <img src="@/assets/images/page.png" alt="" />
        <span>总页:{{ count }}</span>
      </div>
      <a v-if="iswx" class="pdf-footer-down" @click="wxGuide">
        <img src="@/assets/images/down.png" alt="" />
        <span>下载</span>
      </a>
      <a v-else target="_blank" :href="res.resFileUrl" class="pdf-footer-down">
        <img src="@/assets/images/down.png" alt="" />
        <span>下载</span>
      </a>
    </div>
    <van-popup class="mxy-guide" v-model="iswxshow" position="top">
      <img src="@/assets/images/lead.png" alt="" />
    </van-popup>
  </div>
</template>

<script>
import qs from "qs";
import ax from "axios";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default {
  name: "viewpdf",
  props: ["res"],
  data() {
    return {
      iswx: false,
      iswxshow: false,
      pdfh5: "",
      count: 0,
      currListNum: 0,
      isAnd: false,
    };
  },
  watch: {
    file: {
      handler(newV, oldV) {
        if (newV) {
          console.log("file");
          this.getIOPdf(newV);
        }
      },
    },
    res: {
      handler(newV, oldV) {
        if (newV && newV.resFileUrl) {
          this.getIOPdf(newV.resFileUrl);
        }
      },
    },
  },
  mounted() {
    if (this.$wechat.isWeixin()) {
      this.iswx = true;
    } else {
      this.iswx = false;
    }

    // if (this.file != undefined && this.file) {
    //   this.getIOPdf(this.file);
    //   console.log(this.file);
    // } else if (this.res.resFileUrl != undefined && this.res.resFileUrl != "") {
    //   setTimeout(() => {
    //     this.getIOPdf(this.res.resFileUrl);
    //     console.log(this.res.resFileUrl);
    //   }, 1000);
    // }
  },
  methods: {
    getIOPdf(url) {
      let that = this;
      ax.get(url, {
        responseType: "arraybuffer",
      }).then((res) => {
        //console.log(res);
        that.pdfh5 = new Pdfh5("#pdf", {
          data: res.data,
          // logo: {
          //   src: this.logoUrl,
          //   x: 0,
          //   y: 0,
          //   width: 50,
          //   height: 50,
          // }
        });
        that.pdfh5.on("ready", function () {
          that.count = this.totalNum;
        });

        //监听完成事件
        // this.pdfh5.on("complete", function (status, msg, time) {
        //   console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        // })
      });
    },
    wxGuide() {
      this.iswxshow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  #pdf {
    flex: 1;
  }

  &-loading {
    position: absolute;
    font-size: 32px;
    color: #03b9f1;
    z-index: 0;
  }

  &-footer {
    z-index: 999;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    width: 100%;
    height: 100px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    //height: calc(1rem + constant(safe-area-inset-bottom));
    //height: calc(1rem + env(safe-area-inset-bottom));
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 32px;
    border-top: 1px solid #00b8f3;

    div,
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #333;
    }

    img {
      height: 36px;
      margin-right: 0.1rem;
    }

    .count {
    }

    .down {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;

    &-content {
      flex: 1;
      overflow-y: auto;
      margin: 30px;
      line-height: 1.8;
      font-size: 32px;
      padding-bottom: 30px;
    }
    &-footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        padding: 30px;
        border-top: 1px solid #999;
      }
      img {
        width: 50px;
        margin-right: 15px;
      }
    }
  }
}
</style>
