<template>
  <div class="pdfdetails">
    <pdf ref="child" :res="res" />
  </div>
</template>

<script>
import pdf from "@/components/viewpdf.vue";
export default {
  name: "pdfdetail",
  components: { pdf },
  data() {
    return {
      model: "",
      res: "",
      id: this.$route.query.id,
    };
  },
  mounted() {
    if (this.id != undefined && this.id != "") {
      this.getModel();
    }
  },
  methods: {
    getModel() {
      let params = {
        id: this.id,
      };
      this.$api.res.getModel(params).then((r) => {
        let { tag, data, message } = r;
        if (tag == 1) {
          console.log(data);
          this.model = data;
          this.res = data.resModel;
          document.title = this.res.catalogTitle + "-" + this.res.name;
        } else {
          Dialog.alert({
            title: "获取数据失败",
            message: message,
          }).then(() => {
            //刷新
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.pdfdetails {
  width: 100%;
  height: 100%;
}
</style>
